export enum LogType {
  SignUp = 'SIGNUP',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
}

export enum UserType {
  Admin = 'ADMIN',
  FieldWorker = 'USER',
}

export interface SignUpPayload {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  middleName: string;
  dateOfBirth: string;
}

export interface SignInPayload {
  username: string;
  password: string;
}

export interface ConfirmSignUpPayload {
  username: string;
  code: string;
}

export interface ResendSignUpPayload {
  username: string;
}

export interface SubmitForgotPasswordPayload {
  username: string;
  token: string;
  password: string;
}
export interface ForgotPasswordPayload {
  username: string;
  clientMetadata?: {
    [key: string]: string;
  };
}
export interface ChangePasswordPayload {
  currentPassword: string;
  newPassword: string;
}
export interface ConfirmPasswordPayload {
  username: string;
  newPassword: string;
  token: string;
}

export interface ConfirmSignInPayload {
  code: string;
  user: any;
}

export interface CompleteNewPasswordPayload {
  user: any;
  password: string;
  requiredAttributes?: any;
}

export interface LogPayload {
  type: LogType;
}

const LockedPage = () => {
  return (
    <svg
      width="157"
      height="121"
      viewBox="0 0 157 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.8166 119.78C112.277 119.78 139.36 92.9478 139.36 59.8418C139.36 26.7358 112.277 0 78.8166 0C45.4541 0 18.2734 26.8323 18.2734 59.9383C18.2734 93.0443 45.4541 119.78 78.8166 119.78Z"
        fill="#F9F9F9"
      />
      <path
        d="M108.644 119.877C99.4203 120.166 88.3322 119.877 80.4822 119.78C72.7303 120.07 61.5441 120.166 52.3203 119.877C61.4459 114.761 64.6841 105.206 65.0766 98.9321H95.6916C96.3784 105.109 99.6166 114.761 108.644 119.877Z"
        fill="#ECECEC"
        stroke="#CCCCCC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M138.276 19.0142V97.8702C138.276 99.8971 136.706 101.345 134.94 101.345H26.6097C24.7453 101.345 23.2734 99.8006 23.2734 97.8702V19.0142C23.2734 17.1804 24.8434 15.5396 26.6097 15.5396H134.743C136.804 15.6361 138.276 17.1804 138.276 19.0142Z"
        fill="white"
        stroke="#CCCCCC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M138.276 19.0142V86.8671H23.2734V19.0142C23.2734 17.1804 24.8434 15.5396 26.6097 15.5396H134.743C136.804 15.6361 138.276 17.1804 138.276 19.0142Z"
        fill="white"
        stroke="#CCCCCC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <rect x="31.5781" y="24.6782" width="33.1357" height="24.8662" fill="#ECECEC" />
      <rect x="72.6016" y="24.6782" width="55.2261" height="10.1019" fill="#ECECEC" />
      <rect x="72.6016" y="54.9839" width="55.2261" height="10.1019" fill="#ECECEC" />
      <rect x="72.6016" y="38.6655" width="55.2261" height="4.66242" fill="#ECECEC" />
      <rect x="72.6016" y="68.9712" width="55.2261" height="4.66243" fill="#ECECEC" />
      <rect x="31.5781" y="54.9839" width="33.1357" height="24.8662" fill="#ECECEC" />
      <path
        d="M80.58 96.6157C82.15 96.6157 83.4256 95.4574 83.4256 93.8166C83.4256 92.2723 82.2481 91.0176 80.58 91.0176C79.01 91.0176 77.7344 92.1758 77.7344 93.8166C77.7344 95.3609 78.9119 96.6157 80.58 96.6157Z"
        fill="#D6DDE8"
      />
      <path
        d="M1 120.069H156.038"
        stroke="#CCCCCC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0075 52.6992C13.2644 52.6992 15.0306 50.9619 15.0306 48.7419C15.0306 46.522 13.2644 44.7847 11.0075 44.7847C8.75063 44.7847 6.98438 46.522 6.98438 48.7419C6.98438 50.9619 8.84875 52.6992 11.0075 52.6992Z"
        fill="#ECECEC"
      />
      <path
        d="M6.98187 39.8621C8.45375 39.8621 9.72937 38.6074 9.72937 37.1596C9.72937 35.7118 8.45375 34.457 6.98187 34.457C5.51 34.457 4.23438 35.7118 4.23438 37.1596C4.23438 38.7039 5.51 39.8621 6.98187 39.8621Z"
        fill="#ECECEC"
      />
      <path
        d="M150.938 62.6405C153.784 62.6405 156.041 60.4206 156.041 57.6215C156.041 54.8225 153.784 52.6025 150.938 52.6025C148.093 52.6025 145.836 54.8225 145.836 57.6215C145.836 60.4206 148.093 62.6405 150.938 62.6405Z"
        fill="#ECECEC"
      />
      <path
        d="M105.734 52.2643C105.734 55.6232 105.166 58.7023 103.887 61.6413C101.045 68.919 94.9351 74.5173 87.4041 77.0365C84.8464 77.8762 82.1465 78.2961 79.3047 78.2961C64.6689 78.2961 52.875 66.6798 52.875 52.2643C52.875 37.8488 64.6689 26.2324 79.3047 26.2324C93.9404 26.2324 105.734 37.9887 105.734 52.2643Z"
        fill="#ECECEC"
        stroke="#CCCCCC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M85.2294 48.9786V43.8221C85.2294 41.0094 82.7886 38.6655 79.8596 38.6655C78.3951 38.6655 77.0933 39.2906 75.9542 40.2281C74.9779 41.1657 74.327 42.4157 74.327 43.9783V49.1349C72.8625 50.0724 71.7234 51.1662 70.7471 52.7288C69.9335 54.1351 69.4453 55.854 69.4453 57.5728C69.4453 63.0419 74.0016 67.4171 79.6969 67.4171C85.3922 67.4171 89.9484 63.0419 89.9484 57.5728C90.1111 53.8226 88.1585 50.6975 85.2294 48.9786ZM80.9986 58.5104V61.1668C80.9986 61.4793 80.6732 61.7918 80.3478 61.7918H79.2087C78.8833 61.7918 78.5578 61.4793 78.5578 61.1668V59.4479V58.5104C77.5815 58.0416 77.0933 56.9478 77.256 55.854C77.4187 54.9164 78.2324 54.1351 79.2087 53.9789C80.8359 53.6664 82.3004 54.9164 82.3004 56.3228C82.3004 57.2603 81.8123 58.0416 80.9986 58.5104ZM82.6259 47.8848C81.6495 47.5723 80.8359 47.416 79.6969 47.416C78.7205 47.416 77.7442 47.5723 76.7679 47.8848V43.9783C76.7679 43.197 77.0933 42.4157 77.5815 41.947C78.0696 41.4782 78.8832 41.1657 79.6969 41.1657C81.3241 41.1657 82.6259 42.4157 82.6259 43.9783V47.8848Z"
        fill="white"
        stroke="#CCCCCC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default LockedPage;

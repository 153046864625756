import uamApi from './uamApi';
export * from './types';
export * from './useChangePassword';
export * from './useCompleteNewPassword';
export * from './useConfirmPassword';
export * from './useConfirmSignIn';
export * from './useConfirmSignUp';
export * from './useForgotPassword';
export * from './useLogin';
export * from './useLoginWithoutMFA';
export * from './useLogout';
export * from './useLogs';
export * from './useResendSignUp';
export * from './useSignup';
export * from './useSubmitForgotPassword';
export const uamAPIClient = uamApi.create();

import { Location } from 'history';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps, Switch, useHistory } from 'react-router-dom';

import { PATHS } from 'src/appConfig/paths';
import { IRootState } from 'src/redux/rootReducer';
import { Navigator, TenantService } from 'src/services';
import Dev from './Dev';

import AuthContainer from './StartupContainers/AuthContainer';
import DialogContainer from './StartupContainers/DialogContainer';
import NotFound from './StartupContainers/NotFound';
import SplashScreen from './StartupContainers/SplashScreen';
import ToastContainer from './StartupContainers/ToastContainer';

import { Screen } from 'src/modules';
import CustomErrorBoundary from 'src/modules/components/ErrorBoundary/CustomErrorBoundary';
import LoadingContainer from './StartupContainers/LoadingContainer';
import ScrollToTop from './StartupContainers/ScrollToTop';
import { useComponentDidMount } from 'src/hooks';
import NetworkStatus from './StartupContainers/NetworkStatus';
const Dashboard = React.lazy(() => import('./Dashboard'));
const Signin = React.lazy(() => import('./UAMContainer/Signin'));
const Signup = React.lazy(() => import('./UAMContainer/Signup'));
const ForgotPassword = React.lazy(() => import('./UAMContainer/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./UAMContainer/ResetPassword'));
const Welcome = React.lazy(() => import('./UAMContainer/Welcome'));

const Routing: React.FC<{ location: Location }> = (props) => {
  Navigator.setTopHistory(useHistory());

  useComponentDidMount(() => {
    const currentWebTenant = TenantService.getWebTenant();
    TenantService.setTenant({ name: currentWebTenant });
  });

  return (
    <Screen>
      <Suspense fallback={<LoadingContainer />}>
        <Switch location={props.location}>
          <CustomRoute pageRequiredAuth path={PATHS.root} component={Dashboard} exact />
          <CustomRoute pageRequiredAuth path={PATHS.dashboard} component={Dashboard} />
          <CustomRoute path={PATHS.signIn} component={Signin} />
          <CustomRoute path={PATHS.signUp} component={Signup} />
          <CustomRoute path={PATHS.forgotPassword} component={ForgotPassword} />
          <CustomRoute path={PATHS.resetPassword} component={ResetPassword} />
          <CustomRoute path={PATHS.welcome} component={Welcome} />
          <Route path={PATHS.dev} component={Dev} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
      <AuthContainer />
      <DialogContainer />
      <ToastContainer />
      <ScrollToTop />
      <NetworkStatus />
    </Screen>
  );
};

export default Routing;

const CRouting: React.FC<Props> = ({ isAuthenticated, pageRequiredAuth, component, ...rest }) => {
  const renderRoute = (Component: any) => (props: RouteProps) => {
    if (isAuthenticated === null) return <SplashScreen />;

    if ((isAuthenticated && pageRequiredAuth) || (!isAuthenticated && !pageRequiredAuth)) {
      // Before render component, check permission first
      return <Component {...props} />;
    }

    const redirectPath = isAuthenticated ? PATHS.dashboard : PATHS.signIn;
    const redirectProps = {
      to: {
        pathname: redirectPath,
        state: { from: props.location },
      },
    };
    return <Redirect {...redirectProps} />;
  };

  return (
    <CustomErrorBoundary showErrorMessage>
      <Route {...rest} render={renderRoute(component)} />
    </CustomErrorBoundary>
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps & { pageRequiredAuth?: boolean };

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {};

const CustomRoute = connect(mapStateToProps, mapDispatchToProps)(CRouting);

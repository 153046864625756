import { Stack, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { FiWifi, FiWifiOff } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { COLOR_CODE } from 'src/appConfig/constants';

import './styles.scss';

const NetworkStatus = () => {
  const offlineToastRef = useRef(null);

  const setOnline = () => {
    if (offlineToastRef.current) toast.dismiss(offlineToastRef.current);

    toast(
      <Stack direction="row" alignItems="center" gap={2}>
        <FiWifi color={COLOR_CODE.SUCCESS} fontSize={20} />
        <Stack>
          <Typography fontWeight={700}>You're online now</Typography>
          <Typography variant="body2" color={COLOR_CODE.GREY_400}>
            Hurray! Internet is connected.
          </Typography>
        </Stack>
      </Stack>,
      {
        autoClose: 5000,
        theme: 'light',
        className: 'cmp-network-status-toast',
      }
    );
  };
  const setOffline = () => {
    offlineToastRef.current = toast(
      <Stack direction="row" alignItems="center" gap={2}>
        <FiWifiOff color={COLOR_CODE.GREY_600} fontSize={20} />
        <Stack>
          <Typography fontWeight={700}>You're offline now</Typography>
          <Typography variant="body2" color={COLOR_CODE.GREY_400}>
            Oops! Internet is disconnected.
          </Typography>
        </Stack>
      </Stack>,
      {
        autoClose: false,
        theme: 'light',
        className: 'cmp-network-status-toast',
      }
    );
  };

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return null;
};

export default NetworkStatus;

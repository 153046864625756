/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import cn from 'classnames';
import React from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';

import { Stack, Typography } from '@mui/material';
import appConfig from 'src/appConfig';
import { COLOR_CODE, COMMON_TYPE } from 'src/appConfig/constants';
import { emptyFunction } from 'src/utils';
import './styles.scss';

const DEFAULT_MESSAGE = (
  <Typography variant="body1" className="fw-medium" color={COLOR_CODE.GREY_600}>
    <span>
      Drag and Drop your files here or{' '}
      <span
        style={{
          color: COLOR_CODE.PRIMARY_400,
        }}
      >
        Browse files
      </span>
    </span>
  </Typography>
);

// eslint-disable-next-line react/display-name
const FileUpload = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      onChange,
      numberAllow = null,
      onError = emptyFunction,
      acceptFileType,
      message = DEFAULT_MESSAGE,
      errorMessage,
      showRequiredFileInfo = true,
      maxSize = '50M',
      maxSizeError = '',
      fileTypeError = '',
    },
    innerRef
  ) => {
    // const errorMessage = 'File format is incorrect. Please upload another file';
    const [myFiles, setMyFiles] = React.useState<File[]>([]);
    const [rejectFiles, setRejectFiles] = React.useState<FileRejection[]>([]);

    const hasError = !!errorMessage;

    const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setMyFiles(acceptedFiles);
      setRejectFiles(fileRejections);
    };

    const handleChange = (files: File[]) => {
      onChange(files);
    };

    // List MIME can be found here:
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: acceptFileType || COMMON_TYPE,
      maxSize: appConfig.MAXIMUM_FILE_SIZE,
    });

    React.useEffect(() => {
      if (rejectFiles.length > 0) {
        const errorMessages = rejectFiles.map((file) =>
          file.file?.size > appConfig.MAXIMUM_FILE_SIZE
            ? maxSizeError ||
              `${file.file?.name} size is greater than ${maxSize}. Please try again.`
            : fileTypeError || `${file.file?.name} format is incorrect. Please upload another file`
        );
        setTimeout(() => onError(errorMessages.join('\n')), 150);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rejectFiles]);

    React.useEffect(() => {
      if (!!numberAllow && myFiles.length > numberAllow) {
        return onError(`Can not upload more than ${numberAllow} files`);
      }
      if (myFiles.length > 0) handleChange(myFiles);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myFiles, numberAllow]);

    // For more info about react dropzone follow:
    // https://react-dropzone.js.org/
    return (
      <Stack className={cn(className, 'cmp-file-upload')}>
        <Stack
          {...getRootProps({
            className: cn('cmp-file-upload__body', { 'cmp-file-upload__body--error': hasError }),
          })}
        >
          <input
            data-testid="upload-input"
            {...getInputProps()}
            {...(innerRef && {
              ref: innerRef,
            })}
          />
          <Stack my={1} justifyContent="center" alignItems="center">
            {message}
            {hasError && (
              <Typography
                mt="3px"
                mx={2}
                variant="body2"
                color={COLOR_CODE.DANGER}
                whiteSpace="pre-line"
              >
                {errorMessage}
              </Typography>
            )}
            {showRequiredFileInfo && (
              <>
                <Typography mt={1} color={COLOR_CODE.GREY_500} variant="body2">
                  Max file size:{' '}
                  <span
                    style={{
                      color: COLOR_CODE.GREY_700,
                    }}
                  >
                    50MB
                  </span>
                </Typography>
                <Typography color={COLOR_CODE.GREY_500} variant="body2">
                  Supported file types:{' '}
                  <span
                    style={{
                      color: COLOR_CODE.GREY_700,
                    }}
                  >
                    DOC, EXCEL, PDF, CSV, JPG, PNG
                  </span>
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  }
);

type Props = {
  className?: string;
  numberAllow?: number;
  onChange: (...args: any[]) => void;
  onError?: (value: any) => void;
  acceptFileType?: Accept;
  message?: string | React.ReactNode;
  innerRef?: React.Ref<HTMLInputElement>;
  errorMessage?: string;
  showRequiredFileInfo?: boolean;
  maxSize?: string;
  maxSizeError?: string;
  fileTypeError?: string;
};

export default FileUpload;

import apisauce from 'apisauce';
import axios from 'axios';
import appConfig from 'src/appConfig';
import { TokenService } from 'src/services';
import { newCancelToken } from 'src/utils';

axios.defaults.withCredentials = true;

const create = (baseURL = appConfig.API_URL) => {
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 0,
      Accept: 'application/json',
    },
    timeout: appConfig.CONNECTION_TIMEOUT,
  });

  api.axiosInstance.interceptors.request.use((config) => {
    return TokenService.getToken()
      .then((token) => {
        config.headers.Authorization = 'Bearer ' + token;

        return Promise.resolve(config);
      })
      .catch(() => {
        return Promise.resolve(config);
      });
  });

  const getRoot = () => api.get('');

  // ====================== Profile ======================
  const getMyProfile = () => api.get('/cupcake-api/v1/me', {}, newCancelToken());

  const getMyPermissions = () => api.get('/cupcake-api/v1/permissions/me', {}, newCancelToken());

  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  //
  // Notice we're not returning back the `api` created in step 1. That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    getRoot,
    // ====================== Profile ======================
    getMyProfile,
    getMyPermissions,
  };
};

export type Apis = ReturnType<typeof create>;

export default {
  create,
};

import { Stack, Typography } from '@mui/material';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
  useLoadScript,
} from '@react-google-maps/api';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RiShareBoxFill } from 'react-icons/ri';
import { connect } from 'react-redux';
import appConfig from 'src/appConfig';
import { COLOR_CODE } from 'src/appConfig/constants';
import { CustomDropdown, LoadingCommon, View } from 'src/modules';
import { IRootState } from 'src/redux/store';
import { Callback } from 'src/redux/types';
import { isEmpty } from 'src/validations';
import { v4 as uuid } from 'uuid';
import {
  DEFAULT_CONTAINER_STYLE,
  HONOLULU_DEFAULT_PIN,
  MAP_ICONS,
  MAP_STYLES,
  getLocationsBelongsToCluster,
} from './helpers';

import './styles.scss';

const Map: React.FC<Props> = ({
  containerStyle,
  locations = [],
  mapHeading = '',
  onClickMarker,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: appConfig.GOOGLE_API_KEY,
  });

  const mapRef = useRef(null);
  const [center, setCenter] = useState(HONOLULU_DEFAULT_PIN);
  const [hoveringLocation, setHoveringLocation] = useState(null);
  const [hoveringCluster, setHoveringCluster] = useState(null);
  const [mapStyle, setMapStyle] = useState(MAP_STYLES.hiding);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (isLoaded && mapRef.current && !isEmpty(locations) && locations.length > 1) {
      const mapBounds = new google.maps.LatLngBounds();
      locations.forEach((marker) => {
        mapBounds.extend({ lat: marker.lat, lng: marker.lng });
      });

      mapRef.current?.fitBounds(mapBounds);
    }
  }, [locations, isLoaded]);

  const zoom = useMemo(() => {
    const locationsLength = locations.length;
    switch (true) {
      case locationsLength === 1:
        return 16;
      case locationsLength < 10:
        return 14;
      case locationsLength < 20:
        return 10;
      case locationsLength < 50:
        return 8;
      case locationsLength < 100:
        return 6;
      default:
        return 2;
    }
  }, [locations.length]);

  useEffect(() => {
    const centerPosition = !isEmpty(locations)
      ? { lat: locations[0].lat, lng: locations[0].lng }
      : HONOLULU_DEFAULT_PIN;
    setCenter(centerPosition);
    isLoaded && map && map.setZoom(zoom);
    setHoveringCluster(null);
    setHoveringLocation(null);
  }, [locations, map, zoom, isLoaded]);

  const handleCloseMarkerInfoWindow = () => setHoveringLocation(null);
  const handleCloseClustererInfoWindow = () => setHoveringCluster(null);

  const handleMouseOverMarker = useCallback((position) => {
    setHoveringLocation(position);
    setHoveringCluster(null);
  }, []);

  const handleClickMarkerInInfoWindow = useCallback(
    (location) => () => {
      onClickMarker && onClickMarker(location);
    },
    [onClickMarker]
  );

  const handleMouseOverMarkerClusterer = useCallback(
    (cluster) => {
      const clusterMarkers = cluster.getMarkers();
      if (clusterMarkers.length <= 1) return;

      const firstMarkerPosition = {
        lat: clusterMarkers[0].position.lat(),
        lng: clusterMarkers[0].position.lng(),
      };

      const clusterLocations = getLocationsBelongsToCluster(locations, cluster);
      const combinedContents = (
        <>
          {clusterLocations.map((location, idx) => (
            <View
              isRow
              key={location.id || idx}
              className="my-8"
              justify="space-between"
              align="flex-start"
            >
              <View isRow>
                <img
                  src={MAP_ICONS.red}
                  alt={`${location.lat}-${location.lng}`}
                  className="mr-8 "
                  style={{ width: 20, height: 20 }}
                />
                <span>{location?.content}</span>
              </View>
              <i className="ml-1">
                <RiShareBoxFill
                  color={COLOR_CODE.PRIMARY}
                  onClick={handleClickMarkerInInfoWindow(location)}
                  style={{ cursor: 'pointer', transform: 'translateY(2px)' }}
                />
              </i>
            </View>
          ))}
        </>
      );

      setHoveringCluster({
        lat: firstMarkerPosition.lat,
        lng: firstMarkerPosition.lng,
        content: combinedContents,
      });
      setHoveringLocation(null);
    },
    // },
    [handleClickMarkerInInfoWindow, locations]
  );

  const handleClickMarker = useCallback(
    (location) => () => onClickMarker && onClickMarker(location),
    [onClickMarker]
  );

  const options = useMemo(
    () =>
      isLoaded
        ? {
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },
            styles: mapStyle.styles,
          }
        : null,
    [isLoaded, mapStyle]
  );

  const markerOptions = useMemo(
    () =>
      isLoaded
        ? {
            animation: google.maps.Animation.DROP,
          }
        : null,
    [isLoaded]
  );

  const markerClusters = useMemo(() => {
    return (
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        // gridSize={25}
        onMouseOver={handleMouseOverMarkerClusterer}
      >
        {(clusterer) => (
          <>
            {locations.map((location) => {
              return (
                <Marker
                  key={uuid()}
                  position={location}
                  onClick={handleClickMarker(location)}
                  onMouseOver={() => handleMouseOverMarker(location)}
                  options={markerOptions}
                  clusterer={clusterer}
                  icon={MAP_ICONS.red}
                />
              );
            })}
          </>
        )}
      </MarkerClusterer>
    );
  }, [
    handleClickMarker,
    handleMouseOverMarker,
    markerOptions,
    handleMouseOverMarkerClusterer,
    locations,
  ]);

  const infoWindow = useMemo(
    () =>
      hoveringLocation && (
        <InfoWindow
          onCloseClick={handleCloseMarkerInfoWindow}
          options={{
            disableAutoPan: false,
            pixelOffset: new google.maps.Size(0, -40),
          }}
          position={hoveringLocation}
          key={uuid()}
        >
          <div>{hoveringLocation?.content}</div>
        </InfoWindow>
      ),
    [hoveringLocation]
  );
  const clusterInfoWindow = useMemo(
    () =>
      hoveringCluster && (
        <InfoWindow
          onCloseClick={handleCloseClustererInfoWindow}
          options={{
            disableAutoPan: false,
            pixelOffset: new google.maps.Size(0, -40),
          }}
          position={hoveringCluster}
          key={uuid()}
        >
          <div>{hoveringCluster?.content}</div>
        </InfoWindow>
      ),
    [hoveringCluster]
  );

  const mapStylesDropdownItems = useMemo(
    () => [
      {
        label: 'Default',
        onClick: () => setMapStyle(MAP_STYLES.default),
      },
      {
        label: 'Hiding',
        onClick: () => setMapStyle(MAP_STYLES.hiding),
      },
      {
        label: 'Retro',
        onClick: () => setMapStyle(MAP_STYLES.retro),
      },
      {
        label: 'Night',
        onClick: () => setMapStyle(MAP_STYLES.night),
      },
    ],
    []
  );

  const renderMapStyleDropdown = useMemo(
    () => (
      <Stack direction="row" alignItems="center">
        <Typography fontWeight={700} mr={1}>
          Current mode:
        </Typography>
        <CustomDropdown
          containerClassName="cmp-map__styles-dropdown"
          labelClassName="cmp-map__styles-dropdown--label"
          items={mapStylesDropdownItems}
          label={mapStyle.name}
        />
      </Stack>
    ),
    [mapStyle, mapStylesDropdownItems]
  );

  const handleCloseInfoWindows = useCallback(() => {
    hoveringLocation && setHoveringLocation(null);
    hoveringCluster && setHoveringCluster(null);
  }, [hoveringLocation, hoveringCluster]);

  const handleDragMap = useCallback(() => {
    handleCloseInfoWindows();
  }, [handleCloseInfoWindows]);

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={{ ...DEFAULT_CONTAINER_STYLE, ...containerStyle }}
        options={options}
        center={center}
        zoom={zoom}
        onZoomChanged={handleCloseInfoWindows}
        onLoad={setMap}
        onDragStart={handleDragMap}
      >
        {markerClusters}
        {infoWindow}
        {clusterInfoWindow}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <Typography>Map cannot be loaded right now, sorry.</Typography>;
  }
  return isLoaded ? (
    <View>
      <View isRow justify={mapHeading ? 'space-between' : 'flex-end'} className="mb-1">
        {mapHeading}
        {renderMapStyleDropdown}
      </View>
      {renderMap()}
    </View>
  ) : (
    <View justify="center" style={containerStyle}>
      <LoadingCommon />
    </View>
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    locations: any[];
    containerStyle?: React.CSSProperties;
    mapHeading?: string | ReactNode;
    onClickMarker?: Callback;
  };

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Map);

/* eslint-disable security/detect-object-injection */
/* eslint-disable react-hooks/exhaustive-deps */
import { Auth, Hub } from 'aws-amplify';
import { History } from 'history';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'src/appConfig/paths';
import { useComponentDidMount } from 'src/hooks';
import { LogType, useLogout, useLogs } from 'src/queries';
import { setAuthenticated, setMyPermissions, setProfile } from 'src/redux/auth/authSlice';
import { IRootState } from 'src/redux/rootReducer';
import { Navigator, Toastify, TokenService } from 'src/services';

enum UserCognitoKey {
  KEY = 'custom:user_type',
  ADMIN_TYPE = 'ADMIN',
  USER_TYPE = 'USER',
}

const AuthContainer: React.FC<Props> = ({
  history,
  isAuthenticated,
  onSetAuth,
  onSetProfile,
  onSetMyPermissions,
}) => {
  const { logout } = useLogout();
  const { authLog } = useLogs();

  useEffect(() => {
    Hub.listen('auth', authLogin);
    return () => {
      Hub.remove('auth', authLogin);
    };
  }, [isAuthenticated]);

  useComponentDidMount(async () => {
    try {
      await TokenService.getToken();
      authenticate();
    } catch (error) {
      clearAuth();
    }
  });

  const authLogin = (res: { payload: { event: string; data?: any } }) => {
    const { payload } = res;
    const { event } = payload;
    switch (event) {
      case 'signIn':
        authLog({ type: LogType.Login });
        authenticate();
        break;
      case 'signOut':
        TokenService.clearToken();
        clearAuth();
        break;
      case 'signIn_failure':
        console.log('signin error', payload?.data?.message);
        break;
      default:
        break;
    }
  };

  const clearAuth = () => {
    onSetAuth(false);
  };

  const authenticate = () => {
    if (!isAuthenticated) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          user.getUserAttributes((err, result) => {
            if (!err) {
              const userType = result.find((e) => e.getName() === UserCognitoKey.KEY).getValue();
              if (userType === UserCognitoKey.ADMIN_TYPE)
                return Navigator.jumpToWebAdmin(PATHS.userManagement);
              Toastify.error('Sorry! You do not have permissions to view this page.');
              return authLog(
                { type: LogType.Logout },
                {
                  onSettled: () => logout(),
                }
              );
            }
          });
        })
        .catch(() => {
          clearAuth();
        });
    }
  };

  return null;
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & { history: History };

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {
  onSetAuth: setAuthenticated,
  onSetProfile: setProfile,
  onSetMyPermissions: setMyPermissions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthContainer));

import { UseQueryOptions } from 'react-query';

export enum API_QUERIES {
  CONTENT = '/content',

  // Permissions
  MY_PERMISSIONS = '/permissions/me',

  // Profile
  PROFILE = '/me',
}

export type QueryOptions<T> = Omit<UseQueryOptions, 'QueryKey'> & { QueryKey: T };

import React from 'react';
import { connect } from 'react-redux';

import { IRootState } from 'src/redux/rootReducer';
import { Text, View } from '../common';

const PermissionRestrict: React.FC<Props> = () => {
  return (
    <View className="c-container" flexGrow={1} justify="center" align="center">
      <Text size={20}>
        Sorry! You don't have <b className="has-text-danger">permission</b> to view this page.
      </Text>
    </View>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionRestrict);

/* eslint-disable security/detect-object-injection */
import { flatten } from 'lodash';
import {
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUIDataTableState,
  MUISortOptions,
} from 'mui-datatables';
import React, { memo, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import appConfig from 'src/appConfig';
import { TableBasic } from 'src/modules/components/common';
import { isEmpty } from 'src/validations';
import './styles.scss';

enum TableQueryParams {
  SEARCH = 'search',
  ROWS_PER_PAGE = 'rowsPerPage',
  PAGE = 'page',
  SORT = 'sort',
  FILTER = 'filter',
}

const getInitialTableState = (
  queryParams: URLSearchParams,
  defaultSortOrder?: MUISortOptions
): Partial<MUIDataTableOptions> => {
  let sortOrder;
  if (queryParams?.get('sort')?.includes(':')) {
    const sortOrderSplit = queryParams?.get('sort')?.split(':');
    if (sortOrderSplit.length === 2 && ['asc', 'desc'].includes(sortOrderSplit[1])) {
      sortOrder = {
        name: sortOrderSplit[0],
        direction: sortOrderSplit[1],
      };
    }
  } else {
    sortOrder = defaultSortOrder;
  }

  return {
    searchText: queryParams?.get('search')?.trim(),
    sortOrder,
    rowsPerPageOptions: appConfig.ROWS_PER_PAGE_OPTIONS,
    rowsPerPage: queryParams?.has('rowsPerPage')
      ? Number(queryParams.get('rowsPerPage'))
      : appConfig.ROWS_PER_PAGE,
    page: queryParams?.has('page') ? Number(queryParams.get('page')) : 0,
  };
};

const Table: React.FC<Props> = ({
  isLoading,
  title,
  data,
  tableOptions,
  columns,
  refresh = true,
  defaultSortOrder,
  emptyComponent,
  viewColumns,
  onAction,
  additionalFilterParams = [],
  locationSearch,
  filterSeparator = ',',
}) => {
  const history = useHistory();
  // const location = useLocation();
  const query = new URLSearchParams(locationSearch);

  const tableStateRef = useRef<MUIDataTableState>();

  useEffect(() => {
    if (refresh) {
      handleTriggerAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, locationSearch]);
  const currentState: Partial<MUIDataTableOptions> = getInitialTableState(query, defaultSortOrder);

  const currentFilterList = query
    ?.getAll('filter')
    ?.map((f) => (f ? f.split(filterSeparator) : []));

  const getFilterParams = (filterList?: string[][]) => {
    if (!filterList) return {};
    const params: any = {};

    filterList.forEach((filter: string[], idx: number) => {
      if (filter.length > 0) {
        const column = columns[idx];
        const name = column?.name;
        params[name] = filter;
      }
    });

    return params;
  };

  const getAdditionalParams = (filterList: string[]) => {
    if (isEmpty(filterList)) return {};

    return filterList.reduce((state, key) => {
      const value = query.get(key);
      if (value)
        return {
          ...state,
          [key]: JSON.parse(value),
        };
      return state;
    }, {});
  };

  const getActionParams = () => {
    const rowsPerPage = currentState?.rowsPerPage;
    const page = currentState?.page;
    const searchText = currentState?.searchText;

    const filterTableParams = getFilterParams(currentFilterList);
    const additionalParams = getAdditionalParams(additionalFilterParams);

    let orderParam = null;
    if (!isEmpty(currentState?.sortOrder?.name) && !isEmpty(currentState?.sortOrder?.direction)) {
      orderParam = `${currentState?.sortOrder?.name}:${currentState?.sortOrder?.direction}`;
    }

    const params = {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: currentState?.sortOrder?.name || defaultSortOrder?.name,
      order: orderParam,
      search: searchText,
      ...filterTableParams,
      ...additionalParams,
    };

    return params;
  };

  const setQueryParams = () => {
    const tableState = tableStateRef.current;

    if (tableState?.searchText) {
      query.set(TableQueryParams.SEARCH, tableState.searchText);
    } else {
      query.delete(TableQueryParams.SEARCH);
    }

    if (tableState?.rowsPerPage) {
      const rowsPerPage = tableState.rowsPerPage.toString();
      query.set(TableQueryParams.ROWS_PER_PAGE, rowsPerPage);
    } else {
      query.delete(TableQueryParams.ROWS_PER_PAGE);
    }

    if (tableState?.page) {
      const page = tableState.page.toString();
      query.set(TableQueryParams.PAGE, page);
    } else {
      query.delete(TableQueryParams.PAGE);
    }

    if (tableState?.sortOrder.name && tableState?.sortOrder.direction) {
      const sort = `${tableState?.sortOrder.name}:${tableState?.sortOrder.direction}`;
      query.set(TableQueryParams.SORT, sort);
    } else {
      query.delete(TableQueryParams.SORT);
    }

    if (tableState?.filterList && flatten(tableState.filterList).length > 0) {
      query.delete(TableQueryParams.FILTER);
      tableState.filterList.forEach((f) => {
        query.append(TableQueryParams.FILTER, f.join(filterSeparator));
      });
    } else {
      query.delete(TableQueryParams.FILTER);
    }

    history.push({ search: query.toString() });
  };

  const handleTriggerAction = () => {
    const params = getActionParams();
    onAction(params);
  };

  const handleTableChange = async (action: any, tableState: MUIDataTableState) => {
    tableStateRef.current = tableState;
    switch (action) {
      case 'sort':
      case 'filterChange':
      case 'changeRowsPerPage':
      case 'changePage':
      case 'search':
      case 'resetFilters':
        setQueryParams();
        break;
      default:
        break;
    }
  };

  return (
    <TableBasic
      title={title}
      data={data}
      columns={columns?.map((c, index) => {
        return {
          ...c,
          options: {
            ...c.options,
            filterList: currentFilterList[index],
            // display:
            //   isEmpty(viewColumns) || !c.name
            //     ? 'true'
            //     : (`${viewColumns?.includes(c.name)}` as Display),
          },
        };
      })}
      options={{ ...tableOptions, ...currentState }}
      onTableChange={handleTableChange}
      containerClassName="cmp-table"
      isLoading={isLoading}
      emptyComponent={emptyComponent}
      locationSearch={locationSearch}
    />
  );
};

type Props = {
  title?: React.ReactNode;
  data: any[];
  tableOptions: MUIDataTableOptions;
  columns: MUIDataTableColumn[];
  refresh?: boolean | number | string;
  onAction: (...args: any[]) => void;
  defaultSortOrder?: MUISortOptions;
  isLoading?: boolean;
  emptyComponent?: React.ReactNode;
  viewColumns?: string[];
  additionalFilterParams?: string[];
  locationSearch: string;
  filterSeparator?: string;
};

export default memo(Table);

export const PATHS = {
  root: '/',
  dev: '/dev',
  welcome: '/welcome',
  signIn: '/login',
  logout: '/logout',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  signUp: '/sign-up',
  dashboard: '/dashboard',

  // Users Management
  userManagement: '/user-management',
};

export const PATH_HEADERS = {};

export const HIDE_NAV_PATHS = [];

import React, { Attributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import './styles.scss';
import { View } from '..';

const DropdownContainer: React.FC<PropsWithChildren<Props>> = ({
  label,
  items,
  xPosition = 'left',
  yPosition = 'bottom',
  containerClassName,
  labelClassName,
  children,
}) => {
  const { buttonProps, itemProps, isOpen } = useDropdownMenu(items.length);

  // const handleKeyPress = (item: DropdownItem) => (event: KeyboardEvent) => {
  //   event.preventDefault();
  //   if (event.code === 'Enter') {
  //     item.onClick();
  //   }
  // };

  return (
    <View className={cn('cmp-dropdown', containerClassName)}>
      <button
        className={cn('cmp-dropdown__button-wrap', labelClassName, {
          'cmp-dropdown__button-wrap--open': isOpen,
        })}
        {...buttonProps}
      >
        {label}
      </button>
      <View
        className={cn(
          'cmp-dropdown__menu',
          `cmp-dropdown__menu--x-${xPosition}`,
          `cmp-dropdown__menu--y-${yPosition}`,
          { visible: isOpen }
        )}
      >
        {React.Children.map(children, (child, idx) => {
          if (React.isValidElement(child)) {
            // eslint-disable-next-line security/detect-object-injection
            const item = items[idx];
            return React.cloneElement(child, {
              ...itemProps[`${idx}`],
              className: cn(
                'cmp-dropdown__item',
                { 'cmp-dropdown__item--active': item.isActive },
                { 'cmp-dropdown__item--disabled': item.isDisabled }
              ),
              onClick: item.onClick,
              // onKeyPress: handleKeyPress(item),
            } as Attributes);
          }
          return child;
        })}
      </View>
    </View>
  );
};

export type DropdownItem = {
  label: string | React.ReactNode;
  onClick: (...args: any) => any;
  icon?: string | React.ReactNode;
  isActive?: boolean;
  isDisabled?: boolean;
};

type Props = {
  containerClassName?: string;
  labelClassName?: string;
  label: React.ReactNode;
  items: DropdownItem[];
  xPosition?: 'left' | 'right';
  yPosition?: 'top' | 'bottom';
};

export default DropdownContainer;

import { useMutation, UseMutationOptions } from 'react-query';
import { uamAPIClient } from '.';
import { authResponseWrapper } from '../helpers';
import { LogPayload } from './types';
// < return Data, Error, Payload Type, Context Types >
export function useLogs(options?: UseMutationOptions<any, Error, LogPayload>) {
  const { mutate, isLoading } = useMutation<any, Error, LogPayload>({
    mutationFn: (payload: LogPayload) => authResponseWrapper(uamAPIClient.authLog, [payload]),
    ...options,
  });

  return {
    authLog: mutate,
    isLoading,
  };
}

import { QueryFunction, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ProfileApi } from '.';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { MyProfile } from './types';

export function useProfile(
  options?: UseQueryOptions<ApiResponseType<MyProfile>, Error, MyProfile>
) {
  const handleGetProfile: QueryFunction<ApiResponseType<MyProfile>, API_QUERIES> = () =>
    responseWrapper<ApiResponseType<MyProfile>>(ProfileApi.getMyProfile);

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getMyProfile,
  } = useQuery<ApiResponseType<MyProfile>, Error, MyProfile>([API_QUERIES.PROFILE], {
    queryFn: handleGetProfile,
    refetchOnMount: false,
    enabled: false,
    notifyOnChangeProps: ['data', 'isFetching'],
    select: (data) => data.data,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateProfile = () => queryClient.invalidateQueries([API_QUERIES.PROFILE]);

  return {
    profile: data,
    error,
    isError,
    loading: isFetching,
    getMyProfile,
    handleInvalidateProfile,
  };
}
